/**
 * Removes the trailing slash from a URL string, if present.
 *
 * @param {string} url - The URL string to process.
 * @returns {string} - The URL without a trailing slash, or an empty string for invalid input.
 */

export const removeTrailingSlash = (url?: string): string => {
  if (typeof url !== 'string') return '';
  return url.trim().replace(/\/$/, '');
};
